<template>
  <form ref="funnelForm">
    <CRow>
      <CCol sm="6">
        <CInput
                label="Name"
                v-model="name"
                description="Bitte geben Sie einen Namen für diese E-Mail ein."
                required
        />
      </CCol>
      <CCol sm="6">
        <CInput
                label="E-Mail: Betreff"
                v-model="subject"
                description="Betreff der E-Mail."
                required
        />
      </CCol>
    </CRow>
    <CRow class="mb-3">
      <CCol sm="12">
        <UnlayerEmailEditor
            v-if="messageType.toUpperCase() === 'UNLAYER_JSON'"
            ref="unlayerEditor"
            label="E-Mail: Inhalt"
            :value="message"
            displayMode="email"
        />
        <WysiwygEditor
            v-else
            label="E-Mail: Inhalt"
            required
            v-model="message"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="5">
        <DurationInput
                label="Senden nach"
                description="Zu welcher Zeit nach der Wertermittlung soll diese E-Mail versendet werden?"
                :duration-time="sendAfterDurationTime"
                @duration:time="sendAfterDurationTime = $event"
                :duration-type="sendAfterDurationType"
                @duration:type="sendAfterDurationType = $event"
        />
      </CCol>
      <CCol sm="4">
        <NewsletterGroupSelector
            v-model="groups"
            description="Hier können Sie Ihre Zielgruppen auswählen, die diese automatisierte E-Mail erhalten soll.
                Mit Zielgruppen können Sie gezielte Artikel verfassen und automatisiert an die jeweiligen Zielgruppen senden.
                Wenn keine Zielgruppe ausgewählt ist, bekommen Abonnenten ohne Zielgruppe alle Artikel die Sie verfassen."
        />
      </CCol>
      <CCol sm="3">
        <label for="active">Versand der E-Mail Aktivieren?</label>
        <CInputCheckbox
            label="Aktiv?"
            id="active"
            name="active"
            value="Aktiv"
            :custom="true"
            :checked.sync="active"
            :inline="false"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CButton v-if="!processing" @click="clickHandler" color="success">
          {{ btnText }}
        </CButton>
        <CButton v-else color="primary">
          Processing...
        </CButton>
        <CButton v-if="enableDelete" @click="deleteHandler" color="danger" style="margin-left: 4px">
          Löschen
        </CButton>
        <CButton v-if="enableDelete" @click="sendTestEmailHandler" color="warning" style="margin-left: 4px">
          Test E-Mail versenden
        </CButton>
      </CCol>
    </CRow>
  </form>
</template>

<script>
import WysiwygEditor from '@/components/WysiwygEditor'
import DurationInput from '@/components/DurationInput'
import Checkbox from '@/components/Checkbox'
import axios from 'axios'
import UnlayerEmailEditor from "@/components/UnlayerEditorWrapper";
import _ from 'lodash'
import NewsletterGroupSelector from "@/components/NewsletterGroupSelector";
import commons from "@/commons";

export default {
  name: 'FunnelForm',
  components: { NewsletterGroupSelector, UnlayerEmailEditor, DurationInput, WysiwygEditor, Checkbox },
  props: {
    passedData: {
      type: Object,
      default: () => ( { id: 0 } )
    },
    btnText: {
      type: String,
      required: true
    },
    enableDelete: Boolean,
    processing: Boolean
  },
  data() {
    return {
      id: this.passedData.id || 0,
      realmId: (this.passedData.realm || {}).id || this.passedData.realmId || 1,
      name: this.passedData.name || '',
      subject: this.passedData.subject || '',
      message: this.passedData.message || '',
      messageType: this.passedData.messageType || 'UNLAYER_JSON',
      // messageIsUnlayer: _.isEmpty(this.passedData.message) || commons.isJson(this.passedData.message),
      groups: this.passedData.groups || [],
      active: typeof this.passedData.active == 'boolean' ? this.passedData.active : true,
      sendAfterDurationTime: this.passedData.sendAfterDurationTime || 10,
      sendAfterDurationType: this.passedData.sendAfterDurationType || 'minutes',
    }
  },
  mounted() {
    if (this.messageType.toUpperCase() === 'UNLAYER_JSON' && !_.isEmpty(this.passedData.message)) {
      this.message = JSON.parse(this.message)
    }
  },
  methods: {
    validateBeforeSubmit() {
      return new Promise((resolve, reject) => {
        if (this.$refs.funnelForm.checkValidity()) {
          if (this.messageType.toUpperCase() === 'UNLAYER_JSON') {
            this.$refs.unlayerEditor.saveDesign().then(design => {
              resolve({ ...this.$data, message: JSON.stringify(design), sendAfterDurationType: this.sendAfterDurationType.toUpperCase() })
            }).catch(err => {
              reject(err)
            })
          } else { // backwards compatibility
            resolve({ ...this.$data, sendAfterDurationType: this.sendAfterDurationType.toUpperCase() })
          }
        } else {
          this.$refs.funnelForm.reportValidity()
          reject()
        }
      })
    },
    async clickHandler() {
      const data = await this.validateBeforeSubmit()
      this.$emit('submit', data)
    },
    deleteHandler() {
      this.$modal.showModal(
          'delete',
          null,
          [`<b>FunnelRule</b> (${this.passedData.name})`],
          async () => {
            try {
              let resp = await axios.delete(`/funnel/rule/${this.id}`)
              this.$toaster.makeToast('Erfolgreich!', 'Die Regel wurde erfolgreich gelöscht.')
              await this.$router.push({ name: 'Funnel' })
            } catch (e) {
              this.$toaster.makeToast('Fehler!', 'Die Regel konnte nicht gelöscht werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.')
            }
          }
      )
    },
    async sendTestEmailHandler() {
      const data = await this.validateBeforeSubmit()
      this.$modal.showModal(
          'default',
          'Dieses Funnel testen?',
          [
            `Hiermit wird zum Test dieser Funnel an die E-Mail des aktuellen Users (${commons.getCurrentUser().email}) gesendet.`,
            `<b>Achtung:</b> Dieser Funnel wird vorher gespeichert!`
          ],
          () => {
            this.$emit('sendTestEmail', data)
          },
          () => {}
      )
    }
  }
}
</script>
